<template>
  <div v-if="navigateurCompatible == false">
    Navigateur incompatible.
    <br><br>
    <p v-if="browser.getOS().name =='iOS'">
     L'oeuf se paramètre par bluetooth et votre navigateur ne permet pas la connexion. Sur iPhone, veuillez télécharger Bluefy et autoriser la connexion bluetooth.
      <br><br><br>
      <a href="bluefy://open?url=oeuf.mejnoun.com" class="button">Ouvrir sur Bluefy</a>
      <br><br><br>
      <a href="https://apps.apple.com/fr/app/bluefy-web-ble-browser/id1492822055" class="secondlink">Télécharger Bluefy</a>
    </p>
    
    <p v-else>
      Merci de passer par Chrome, Edge ou Opera.
    </p>
    <p class="secondlink" @click="navigateurCompatible=true">Poursuivre sur ce navigateur</p>
  </div>  

  <div v-else>


    <div v-if="step == 1">
      <p id="info"> Merci d’avoir acheté un oeuf Mejnoun. <br> Connectez vous pour définir sa date d’ouverture.</p>

      <div class ="button" id="findCoffre" v-on:click="connectBluetooth">
          <span v-if="!loading">Se connecter à l'oeuf </span> 
          <img v-if="loading" style="height:16px; padding: 0 75px" src="./assets/Loading_white.gif">
      </div>

      <p v-if="isConnected != true " class="error"> {{error}} </p>
      <p v-if="isConnected == true && (isLocked == '[Nothing]' || isLocked == undefined || isLocked == null  ) " class="error"> Coffre détecté. Initialisation en cours. isLocked : {{ isLocked }} </p>
     </div>

    <div v-if="step == 2">
      <p> Votre oeuf est connecté. <br> Vous pouvez choisir sa date d’ouverture.</p>
      <div class="form">
          <input type="date" id="datepick" class="form-control date-input" v-model="inputDate">
          <div class ="ok-button" id="validate" :class="[isInputDateValid?'':'unactive']" v-on="isInputDateValid ? { click:  setAlarmTime(validInputDate)}:{}">  
              <span v-if="!loading">OK</span>
              <img v-if="loading" style="height:16px; padding: 0 75px" src="./assets/Loading_white.gif">
          </div>
      </div>
      <p class="secondlink" v-on:click="setTwoMinutes">
          <span v-if="!loading">Fermer deux minutes (démo)</span>
          <img v-if="loading" style="height:16px; padding: 0 75px" src="./assets/Loading_white.gif">
      </p>
    </div>


    <div v-if="step == 3 ">
        <p> L'ouverture est programmée pour le {{openeningTime.toLocaleString("fr-FR")}}.<br> Déposez son contenu dans l'oeuf et maintenez l'oeuf fermé pendant 5 secondes pour le verrouiller.</p>
        <br>
        <p v-if="isClosed" class="secondlink" >
            Verrouillage imminent. Ne pas relacher.
        </p> 
         <!--
        <p class="secondlink"  v-on:click="changeDate"> ←  Modifier la date d’ouverture</p>   -->
        <p class="secondlink"  v-on:click="getSwitchStatus">Get switch status</p>  
        <p class="secondlink"  v-on:click="getBattery">Get Battery</p>  

    </div>

    <div v-if="step == 4">
            <p> Votre oeuf est verrouillé. <span v-if="openeningTime">Il s'ouvrira le {{openeningTime.toLocaleString("fr-FR")}}. </span> 
              <span v-else class="error"> <br> Récupération de l'heure d'ouverture en cours</span></p>
            <p v-if="justLocked||isLocked" class="secondlink" v-on:click="quit">Terminer</p>
            <p v-if="isConnected" class="secondlink"  v-on:click="unlock">
                Déverrouiller (démo)
            </p>
    </div>
    <!--
    <button @click="connectBluetooth">Connect</button>
    <button @click="setCurrentTime">Set Current Time</button>
    <button @click="getCurrentTime">Get Current Time</button>
    <button @click="setTwoMinutes">Set 2 minutes</button>
    <button @click="getAlarmTime">Get Alarm Time</button>
    -->

  </div>
</template>

<script>

import Bowser from "bowser";

export default {
  data() {
    return {
      rxCharacteristic : null,
      txCharacteristic: null,
      msgSignature: 170,
      isConnected: false,
      isLocked: null,
      error: null,
      loading: false, 
      openeningTime: null,
      isInputDateValid : false,
      inputDate : Date.now(),
      isClosed: false,
      justLocked: false,
      browser : Bowser.getParser(window.navigator.userAgent),
      navigateurCompatible : null,
      validInputDate : null,
      tensionValue : null
    }
  },
  computed: {

    step(){
      if(this.isConnected != true || (this.isLocked != true && this.isLocked != false)){
        if(this.justLocked == true){
          return 4
        }
          return 1
      } else if( this.isLocked == true){
          return 4
      } else if( this.openeningTime == null || (this.openeningTime - Date.now()/60000) < 0 ){
          return 2
      } else {
          return 3
      }
    },
    ua(){
      return navigator.userAgent
    }
  },
  methods: {
    checkifBrowserisCompatible(){
    console.log("check Browser");

    const navigateur = this.browser.getBrowser().name;
    const OS = this.browser.getOS().name;
    const navigateursOK = ["Chrome","Edge","Opera","Chrome Android","Opera Android","Samsung Internet"];

    if( OS != "iOS"){
      if(navigateursOK.indexOf(navigateur)> -1){
        console.log("compatible")
        return true
      }else{

        console.log("incompatible, pas ios mais",navigateur,"pas dans la liste")

        return false
      }
    }else if(navigator.userAgent.includes("Bluefy")) {
      console.log("compatible")
        return true
    }else{
      console.log("incompatible, ios mais",navigateur, "n'est pas bluefy")
      return false
    }
  },
    async connectBluetooth() {
      const NUS_SERVICE_UUID = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
      const RX_CHARACTERISTIC_UUID = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
      const TX_CHARACTERISTIC_UUID = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';

      
      try {
        console.log('Demande de connexion Bluetooth...');
        this.loading = true;

              if (!navigator.bluetooth) {
                console.error('L\'API Web Bluetooth n\'est pas disponible dans ce navigateur.');
                this.loading = false;
                return;
              }

        const device = await navigator.bluetooth.requestDevice({
          filters: [{ services: [NUS_SERVICE_UUID] }]
        });


        console.log('Connexion au périphérique...');
        const server = await device.gatt.connect();
        await device.addEventListener('gattserverdisconnected', this.onDisconnected);


        console.log('Connexion au service NUS...');
        const service = await server.getPrimaryService(NUS_SERVICE_UUID);

        console.log('Récupération des caractéristiques RX et TX...');
        this.rxCharacteristic = await service.getCharacteristic(RX_CHARACTERISTIC_UUID);
        this.txCharacteristic = await service.getCharacteristic(TX_CHARACTERISTIC_UUID);

        await this.txCharacteristic.startNotifications();
        this.txCharacteristic.addEventListener('characteristicvaluechanged', this.handleNotifications);
        this.isConnected = true;
        this.getisLocked();
        setTimeout( () => { this.getCurrentTime()}, 1000);
        setTimeout( () => { this.getBattery()}, 2000);


        console.log('Connecté et prêt à recevoir des données!');
      } catch (error) {
        console.error('Erreur de connexion Bluetooth:', error);
        this.loading = false;
      }
    },

    handleNotifications(event) {
      const value = event.target.value;
      const msgArr = new Uint8Array(value.buffer);
      console.log("Données reçues");
      console.log(msgArr);
      this.loading = false;

      if (msgArr[0] != 170 || msgArr[2] != (msgArr.length - 3)){
            this.error = "message reçu incorrect";
        }else{ 
          switch(msgArr[1]){
            case 0:
            console.log("code 0. Bien reçu");
              break;
            case 11:
              console.log("code 11");
              var currentTime = new Date(msgArr[3]+2000, msgArr[4], msgArr[5], msgArr[6], msgArr[7],msgArr[8]);
              if(Math.abs(currentTime.getTime() - Date.now()) < 1000){
                console.log("horloge à l'heure")
              }else{
                let now = new Date()
                console.log("RTC à régler" +  currentTime);
                console.log("Heure actuelle" + now);
                this.error = "Horloge déréglée, ajustement en cours"
                this.setCurrentTime()
              }
              console.log("code 11. current time : "+ currentTime);
              break;
            case 21: 
              this.openeningTime = new Date(msgArr[3]+2000, msgArr[4], msgArr[5], msgArr[6], msgArr[7]);
              console.log("code 21. opening time : "+ this.openeningTime);
              break;
            case 91:
            this.isLocked = true;
            this.justLocked = true;
              console.log("code 91. CAPSULRE IS CLOSED");
              break;
            case 101:
            if(msgArr[3] === 1){
                this.isClosed = false;
                console.log("code 101. Switch relaché");
              }else{
                this.isClosed = true;
                console.log("code 101. Switch actionné");
              }break;
            case 111:
              if(msgArr[3] === 1){
                this.isLocked = true;
                console.log("code 111. Coffre verrouillé");
                this.getAlarmTime();
              }else{
                this.isLocked = false;
                console.log("code 111. Coffre déverrouillé");
              }break;
            case 121:
            
              //Buffer.from(dataArray)
              //"func": "var msgSignature = 170;\nvar msgId = 121; \n\nvar dataArray = []\n\nvar dataLength = dataArray.length;\ndataArray.unshift(msgSignature, msgId, dataLength);\n\nmsg.payload = Buffer.from(dataArray);\n\nreturn msg;",
              
              var i = 3;
              var output = 0;
              for (var j = 1; j >= 0; j--) {
                  output = (output << 8) | msgArr[i + j];
                }
              console.log("tension parsed : ",output);
              this.tensionValue = output;
              break;
            default:
              console.log("message non reconnu. Type : " + msgArr[1])
          }
        }
    },

    async sendToBluetooth(msgId,dataArray){
      var msgArray = dataArray;
      msgArray.unshift(this.msgSignature, msgId, dataArray.length);
      var msg = new Uint8Array(msgArray);

      if (this.rxCharacteristic) {
          try {
              await this.rxCharacteristic.writeValue(msg);
              console.log('Données envoyées:', msg);
          } catch (error) {
              console.error('Erreur lors de l\'envoi des données:', error);
          }
      }
    },

    getisLocked(){
      this.sendToBluetooth(111,[]);
    },

    setAlarmTime(alarm){
      var dataArray = [alarm.getFullYear() - 2000, alarm.getMonth(), alarm.getDate(), alarm.getHours(), alarm.getMinutes()]
      this.sendToBluetooth(20, dataArray);
      setTimeout(() => {this.getAlarmTime()},500);
    },

    getAlarmTime(){
      console.log("get alarmtime")
      this.sendToBluetooth(21, []);
    },

    setTwoMinutes(){
      var date = new Date();
      date.setMinutes(date.getMinutes() + 2);
      this.setAlarmTime(date);
    },

    setCurrentTime(){
      var today = new Date();
      var dataArray = [today.getFullYear() - 2000, today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds()]
      this.sendToBluetooth(10, dataArray);
      setTimeout(() => {this.getCurrentTime()},500);

    },

    getBattery(){
      console.log('get battery');
      this.sendToBluetooth(121, []);  
    },

    getCurrentTime(){
      this.sendToBluetooth(11, []);
    },
    
    onDisconnected(){
      console.log("Device deconnecté")
      console.log(this.$data);
      this.isConnected = false;
      if(this.justLocked != true){
        this.quit();
      }
    },

    getSwitchStatus(){
      this.sendToBluetooth(101, []);
    },

    quit(){
      console.log("quit");
      console.log(this.$data);
      Object.assign(this.$data, this.$options.data.call(this));
    },

    unlock(){
      this.sendToBluetooth(80, [])
    }

  },

  watch: {
    inputDate: function(val){
        var dateInInput = new Date(val);
        dateInInput > Date.now() ? this.isInputDateValid = true : this.isInputDateValid = false;
        this.validInputDate = dateInInput;
        console.log(dateInInput);
    },
  },
  mounted() {
    this.navigateurCompatible = this.checkifBrowserisCompatible()
    console.log("navigateur compatible ?",this.navigateurCompatible );
  }
}
</script>

<style>
@import url('./assets/style.css');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  align-self: center;
    width: 80vw;
    margin: 0 10vw;
}

a{
  color: inherit;
  text-decoration: inherit;
}
</style>
